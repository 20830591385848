import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SEO from '../components/seo';
import PresentationListItem from '../features/presentation/presentationListItem';

const useLectures = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/content/lectures/.*/index.md/" } }) {
        nodes {
          frontmatter {
            title
            date
            description
            materialsUrl
            videoUrl
            # events
            notPublished
          }
          fields {
            slug
          }
        }
      }
    }
  `);
  return data.allMarkdownRemark.nodes;
};

const PresentationsPage = () => {
  const presentations = useLectures();

  return (
    <>
      <SEO title="Prezentacje" />
      <>
        <h1>Prezentację</h1>
        <p>
          Lubię dzielić się wiedzą. Z tego powodu staram się co jakiś czas przygotować prezentacje, które przedstawiam na lokalnych
          meetupach lub konferencjach.
        </p>
        {presentations.map((presentation) => {
          if (presentation.frontmatter.notPublished) {
            return null;
          }
          return (
            <PresentationListItem
              key={`presentation-${presentation.fields.slug}`}
              slug={presentation.fields.slug}
              title={presentation.frontmatter.title}
              description={presentation.frontmatter.description}
              materialsUrl={presentation.frontmatter.materialsUrl}
              events={presentation.frontmatter.events}
              videoUrl={presentation.frontmatter.videoUrl}
            />
          );
        })}
      </>
    </>
  );
};

export default PresentationsPage;
