import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const PresentationLinksContainer = styled('div')`
  * {
    color: #222;
    font-size: 1rem;
    line-height: 1;
    margin: 0 0.5rem 0 0;
    padding: 0.25rem;
    text-decoration: none;

    &.current-page {
      border-bottom: 2px solid #222;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;
const PresentationListItem = ({ title, slug, description, materialsUrl, events, videoUrl }) => {
  return (
    <>
      <h2>{title}</h2>
      <PresentationLinksContainer>
        <Link to={`/presentation${slug}/1`}>Prezentacja</Link>
        {materialsUrl && <a target="_blank" rel="noopener noreferrer" href={materialsUrl}>
          Materiały
        </a>}
        {Boolean(videoUrl) && (
          <a target="_blank" rel="noopener noreferrer" href={videoUrl}>
            Wideo
          </a>
        )}
      </PresentationLinksContainer>

      <p>{description}</p>
      <ul>{Boolean(events) && events.map((event, index) => <li key={`presentation-events-${slug}-${index}`}>{event}</li>)}</ul>
    </>
  );
};

export default PresentationListItem;
